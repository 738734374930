"use strict";

let sliderId = 0;

export function initInScope ($scope) {
    let $gallerySlider = $scope.find('.js-thumb-slider');

    import('slick-carousel').then(function () {
        $gallerySlider.each(function () {
            let $container = $(this);
            let $mainSlider = $container.find('.js-thumb-slider__main');
            let $thumbSlider = $container.find('.js-thumb-slider__thumbs');
            let id = sliderId++;

            $mainSlider.attr('id', 'gallery-slider__main-' + id);
            $thumbSlider.attr('id', 'gallery-slider__thumb-' + id);

            $mainSlider.slick({
                asNavFor: '#gallery-slider__thumb-' + id,
                dots: true, /*should always be true; hide with css (accessibility)*/
                dotsClass: 'sr-only',
                slidesToShow: 1,
                swipeToSlide: true,
                infinite: true,
                slidesToScroll: 1,
                arrows: true,
                prevArrow: '<button type="button" aria-label="previous" class="btn-no-styling slick-arrow slick-prev"><span class="icon icon-arrow-bold icon-rotate-180"></span></button>',
                nextArrow: '<button type="button" aria-label="next" class="btn-no-styling slick-arrow slick-next"><span class="icon icon-arrow-bold"></span></button>',
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            asNavFor: null
                        }
                    }
                ]
            });

            if ($thumbSlider && $thumbSlider.length) {
                $thumbSlider.slick({
                    dots: true,
                    dotsClass: 'sr-only',
                    slidesToShow: 5,
                    swipeToSlide: true,
                    arrows: false,
                    centerMode: false,
                    asNavFor: '#gallery-slider__main-' + id,
                    focusOnSelect: true
                });
            }

            let $tabPaneParent = $mainSlider.closest('.tab-pane');
            if ($tabPaneParent && $tabPaneParent.length) {
                /*refresh the slider when it was hidden in a tab*/
                let $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-toggle=tab][data-target="#' + $tabPaneParent.attr('id') + '"]');
                $tabTrigger.one('shown.bs.tab', function () {
                    $mainSlider.slick('setPosition');
                    $thumbSlider.slick('setPosition');
                });
            }
        });
    });
}
