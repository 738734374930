"use strict";

import {add} from '@elements/scroll-animations';

export function initInScope($scope) {
    add(
        $scope.find('.js-transform-in'),
        calculateAnimationProgress,
        setAnimationProgress
    );
}

// gets element -> returns number between 100 and 0
function calculateAnimationProgress(element) {
    const start = window.innerHeight / 8 * 7;
    const end = window.innerHeight / 2;

    return {
        opacity: 1 - Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end),1),0),
        transform: Math.max(Math.min((element.getBoundingClientRect().top - end) / (start - end), 1),0) * 100
    };
}

// gets element & progress (number between 0 - 1) -> sets style of element
function setAnimationProgress(element, progress) {
    element.style.opacity = progress.opacity;

    switch ($(element).data('transform-start')) {
        case 'right':
            element.style.transform = 'translate3d('+ progress.transform +'px, 0, 0)';
            break;
        default:
            element.style.transform = 'translate3d(-'+ progress.transform +'px, 0, 0)';
    }
}